.about-container {
    display: flex;
    justify-content: center; /* Centers the items horizontally */
    align-items: center; /* Vertically centers the items */
    padding: 20px;
    width: 90%;
    margin: 0 auto; /* Centers the container itself within its parent */
  }
  
  .left-column {
    flex: 1;
    padding-right: 20px; /* Optional: space between the columns */
    max-width: 50%; /* Limit the width of the left column */
    color: rgb(77, 76, 76);
  
  }
  
  .right-column {
    flex: 1;
    max-width: 80%; /* Increase the width of the right column */
  }
  
  .right-column img {
    width: 100%; /* Ensures the image is responsive */
    height: 300px;
    border-radius: 10px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column; /* Stacks the columns vertically on small screens */
      align-items: flex-start; /* Aligns items to the left in the column */
      padding: 20px 10px; /* Adjust padding for smaller screens */
    }
  
    .left-column,
    .right-column {
      max-width: 100%; /* Each column takes full width */
      padding-right: 0; /* Remove right padding on small screens */
      margin-bottom: 20px; /* Optional: add space between the columns */
      margin-top: 20px;
    }
  
    .right-column img {
        margin-top: 30px;
      width: 100%; /* Image will now take full width of the container */
      height: auto; /* Adjust height automatically to maintain aspect ratio */
    }
  }
  