.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 180px;
}

.blog-centered-photo {
  width: 70%;
  margin: 0 auto;
  display: block;
  height: 409px;
}

.blog-title {
  text-align: left;
  margin: 20px auto;
  width: 80%;
}

.blog-lorem-text,
.blog-text {
  margin: 20px auto;
  font-size: 16px;
  color: #333;
  width: 80%;
  text-align: justify;      /* Change to justify for full justification */
  text-justify: inter-word; /* Optional, helps with spacing between words */
}

.blog-list {
  margin: 20px auto;
  width: 80%;
  text-align: left;
}
.accordion-container {
  display: flex;              
  flex-direction: column;   
  width: 80%;               
  margin: 0 auto;           
}


.accordion-item {

  margin-bottom: 10px;
}

.accordion-header {
  background-color: #f3f3f3cf;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  padding: 10px;
  border-top: 1px solid #ccc;
}
@media screen and (max-width: 1024px) {
  .blog-centered-photo {
    display: none;
  }

}