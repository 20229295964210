.m6-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  ;
}

.m6-image-container {
  position: relative;
  width: 100%;
  max-width: 90%;
}

.m6-image {
  width: 100%;
  height: auto; /* Let the height adjust based on the width to maintain aspect ratio */
}

/* Style for the dot label */
.m6-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #035CEE; /* Blue dot */
  border-radius: 50%; /* Make the element round */
  cursor: pointer; /* Pointer cursor to indicate interactivity */
  transition: transform 0.3s ease;
  z-index: 2; /* Ensure the dot stays on top of the orbit */
}

.m6-dot-orbit {
  position: absolute;
  width: 18px; /* Orbit's size (adjustable) */
  height: 18px; /* Orbit's size (adjustable) */
  border: 10px solid yellow; /* Yellow orbit */
  border-radius: 50%; /* Make it a circle */
  animation: pulse-orbit 2s infinite ease-in-out; /* Orbit pulsing effect */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the orbit around the dot */
}

/* Pulsing effect for the orbit */
@keyframes pulse-orbit {
  0% {
    transform: translate(-50%, -50%) scale(1); /* Initial size */
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5); /* Grow the orbit */
  }
  100% {
    transform: translate(-50%, -50%) scale(1); /* Shrink back to original size */
  }
}

/* Pulsing effect for the blue dot */
@keyframes pulse-dot {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.3); /* Grow the dot */
  }
  100% {
    transform: scale(1); /* Shrink back to original size */
  }
}

/* Show the message box when hovering over the dot */
.m6-dot:hover .msg {
  opacity: 1; /* Make the message box visible on hover */
  display: block; /* Ensure it's shown when hovering */
}

/* Message box styling */
.msg {
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%; /* Position the message box in the middle of the dot */
  left: -160%; /* Position the message box to the left of the dot */
  transform: translateY(-50%); /* Center the message box vertically */
  background-color: #ffffff; /* White background */
  color: rgb(0, 0, 0); /* Black text */
  padding: 15px; /* Increase padding */
  border-radius: 8px; /* Slightly larger rounded corners */
  font-size: 16px; /* Larger font size */
  width: 200px; /* Increase width */
  height: auto;
  text-align: center;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Larger shadow effect */
}
.m-header{
  font-size: 3rem;
  color: #035CEE;
  text-align: center;

}
/* Responsive adjustments for different screen sizes */
@media (max-width: 800px) {
  .m6-dot {
    position: absolute;
    width: 5px;
    height: 5px;
    z-index: 0; 
  }
  .m-header{
    font-size: 20px;
  
  }
  
  .m6-dot-orbit {
    position: absolute;
    width: 5px; 
    height: 5px; 
    border: 5px solid yellow;
    z-index: 0;
  }
  .msg {
    top: 10%; /* Position the message box in the middle of the dot */
    left: -60%; /* Position the message box to the left of the dot */
    z-index: 1000;
    width: 110px;
  }
  
}
