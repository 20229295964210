/* BlogSection.css */

.blog-section {
  width: 90%;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: 2.8rem;
  color: #035cee;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
  letter-spacing: -0.5px;
}

.blog-grid {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.blog-card {
  background: #F9FBFF;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 280px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  cursor: pointer;
  margin-bottom: 30px;
}

.blog-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-link {
  text-decoration: none;
  color: inherit;
}

.blog-image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.category-tag {
  position: absolute;
  top: 16px;
  left: 16px;
  background: #0A0A23;
  color: #FFFFFF;
  padding: 6px 14px;
  border-radius: 12px;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.blog-content {
  padding: 20px;
}

.blog-title {
  font-size: 1.4rem;
  color: #0A0A23;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1.4;
}

.blog-description {
  font-size: 1rem;
  color: #4A4A68;
  margin-bottom: 20px;
  line-height: 1.6;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.view-all-button {
  background: #FFC107;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 30px;
  letter-spacing: 1px;
}

.view-all-button:hover {
  background: #e0a800;
}

@media (max-width: 768px) {
  .blog-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .section-title {
    font-size: 2.4rem;
  }

  .blog-card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2rem;
  }

  .blog-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .view-all-button {
    width: 100%;
  }
}
