.privacy-policy {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    width: 90%;          /* Set width to 90% */
    margin: 0 auto;     /* Center the element */
    margin-top: 100px;
}

h1 {
    margin-bottom: 20px;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin: 10px 0;
}
