.l2-container {
    height: 80vh;
    background: url('../finalImage/towerland.jpg') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 0 20px;
}

@media screen and (max-width: 1024px) {
    .l2-container {
        background: url('../finalImage/towerPhone.jpg') no-repeat center center/cover;
    }
}
