/* Container for the grid of images */
.m7-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3 equal columns */
  gap: 0px; /* Gap between the items */
  align-items: center;
  justify-items: center;
}

/* Each step (image + description + arrow) */
.m7-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px; /* Fixed width for each step (image + description + arrow) */
  text-align: center;
  position: relative; /* To position the arrow between items */
}

/* Image styling */
.m7-image {
  width: 230px;
  height: 200px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Description styling */
.m7-description {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px; /* Space between description and the arrow */
}

/* Right arrow styling */
.m7-arrow {
  font-size: 3rem;
  color: #007bff; /* Blue color for the arrow */
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Center the arrow vertically */
  right: -100%; /* Adjust right position to place the arrow between items */
}

/* U-turn arrow styling */

/* Hover effect on the arrow */
.m7-arrow:hover, .m7-arrow-u-turn:hover {
  transform: translateX(5px) translateY(-50%); /* Slightly move the arrow on hover */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .m7-container {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 equal columns on mobile */
  }
  
  .m7-image {
    width: 130px;
    height: 200px;
  }
  .m7-arrow {
    display: none;
  }
}
