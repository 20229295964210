.phasefive-container {
  width: 100%;
  background-color: #003366;
  color: #fff;
  padding: 20px;
  box-sizing: border-box; /* Ensures padding is included in the total width */
}
.heading-phaseHead{
  font-size: large;
  font-weight: bold;
  text-align: center;
}
.phasefive-content {
  width: 80%;
  margin: 0 auto; /* Center the content horizontally */
  font-size: 1.2rem;
  text-align: justify;
  position: relative; /* To allow image positioning within the paragraph */
  line-height: 1.6; /* Increase line spacing */

}

.paragraph-image {
  float: right; /* Align image to the right side */
  margin: 0 0 10px 10px; /* Margin around the image (top-right-bottom-left) */
  width: 500px; /* Adjust size as needed */
  height: 400px; /* Maintain aspect ratio */
  border-radius: 30px;
  padding: 10px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .phasefive-container {
    width: auto;

  }
  .phasefive-content {
    font-size: 1rem;
    width: 100%; /* Increase width to use more of the available space */
  }

  .paragraph-image {
    width: 60%; /* Adjust size for smaller screens */
  }
}

@media (max-width: 480px) {
  .phasefive-content {
    font-size: 0.9rem; /* Further adjust font size for very small screens */
  }

  .paragraph-image {
  
    height: 300px;
    float: none;
    display: block;
    margin: 5px -10px;
    width: 97%;
  }
}
