/* Testimonial.css */

/* Overall container for the testimonial section */
.testimonial-container {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: flex-start; /* Align items to the top */
  gap: 30px;
  margin: 40px auto;
width: 90%;
  padding: 0 20px; /* Padding for smaller screens */
  flex-wrap: wrap; /* Allow wrapping for smaller screen sizes */
}

/* Left section for text and button */
.testimonial-left {
  flex: 1 1 300px; /* Allow the left section to grow but keep a minimum width */
  padding: 20px;
  border-radius: 10px;
}

.testimonial-left h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.testimonial-left p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
  line-height: 1.5;
}

/* Button styling */
.testimonial-btn {
  padding: 12px 24px;
  background: linear-gradient(to right, #D95DB0, #3344DC);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.testimonial-btn a {
  color: white;
  text-decoration: none;
}

.testimonial-btn:hover {
  background: linear-gradient(to right, #3344DC, #D95DB0);
}

/* Right section for images */
.testimonial-right {
  flex: 1 1 600px; /* Allow right section to grow and be responsive */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow images to stack on smaller screens */
}

.testimonial-image-container {
  position: relative;
  flex: 1; /* Equal width for each image container */
  max-width: 45%; /* Max width to allow space between images */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow around images */
}

/* Styling for images */
.testimonial-image {
  width: 80%; /* Full width of the container */
  height: 100%;
  object-fit: cover; /* Ensure the image maintains aspect ratio */
  border-radius: 10px;
  transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

/* Hover effect for images */
.testimonial-image-container:hover .testimonial-image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  bottom: 0;
  background: linear-gradient(to top, rgba(3, 93, 238, 0.852), rgba(255, 255, 255, 0.362));

  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Prevent overlay from blocking mouse events */
}

.overlay-text {
  color: white;
  font-size: 24px;
  text-align: center;
  opacity: 0;
  width: 90%;
  text-align: justify;
  transition: opacity 0.3s ease;
}

/* On hover, show the overlay and text */
.testimonial-image-container:hover .overlay {
  opacity: 1; /* Show the blue tint */
}

.testimonial-image-container:hover .overlay-text {
  opacity: 1; /* Show the text */
}

/* Responsiveness for small screens */
@media (max-width: 1024px) {
  .testimonial-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .testimonial-left, .testimonial-right {
    flex: 1 1 100%; /* Make sections take full width on small screens */
    margin-bottom: 20px;
  }
  .overlay-text {
    font-size:small;
    width: 90%;
    text-align: center;
    font-size: 20px;
  }

  .testimonial-image-container {
    max-width: 90%; /* Adjust image container width for mobile */
    margin: 10px 0; /* Space between images */
  }
}

@media (max-width: 480px) {
  .testimonial-left h2 {
    font-size: 20px; /* Smaller heading for small screens */
  }

  .testimonial-left p {
    font-size: 14px; /* Smaller paragraph text */
  }

  .testimonial-btn {
    padding: 10px 20px; /* Smaller button for small screens */
    font-size: 14px;
  }
}
