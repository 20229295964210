/* General Reset */
html, body {

  
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.phase-two-container {
    width: 100vw;
    height: 80vh; /* Height of the container */
    background: url('../finalImage/patienland.jpg') no-repeat center /cover; /* Background image */
    display: flex; /* Flexbox layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    color: white; /* Text color */
    text-align: center; /* Center text */
    padding: 0; /* No padding */
    position: relative; /* Position relative for child elements */
    overflow: hidden; /* Prevent overflow */
}

/* Media Queries for mobile */
@media screen and (max-width: 768px) {
    .phase-two-container {
        background: url('../finalImage/pateintphon.jpg') no-repeat center center/cover; /* Mobile background image */
    }
}
