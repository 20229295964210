.landing-page {
  height: 80vh;
  background: url('../imgnew/Group\ 17\ \(5\).jpg') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 20px;

  position: relative;
}
/* Wrapper with white background opacity */

/* Image boxes container for PC */
.pc-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: -90px;
}

/* Hide mobile images on PC */
.mobile-images {
  display: none;
}

/* Image box styling */
.image-box {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack the content vertically */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  margin-bottom: 1;
}

.image-box .box-content {
  color: white; /* Text color inside the box */
  font-size: 14px;
  text-align: center;
  line-height: 1.4;
}

.image-box i {
  font-size: 40px; /* Icon size */
  margin-bottom: 10px; /* Space between icon and text */
}

/* Hover effect on box */
.image-box:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

/* PC Image Box Colors */
.box-pc-1 {
  background-color: #035CEE; /* Green */
  border-radius: 20px 0px 0px 20px ;
 
}

.box-pc-2 {
  background-color: #035CEE; /* Blue */
}

.box-pc-3 {
  background-color: #035CEE; /* Orange */
}

.box-pc-4 {
  background-color: #035CEE; /* Red */
  border-radius: 0px 20px 20px 0px ;
}


.box-pc-1:hover {
  background-color: #0043E7; /* Darker green on hover */
  border-radius: 20px;
  height: 250px;
}

.box-pc-2:hover {
  background-color: #0043E7; /* Darker blue on hover */
  border-radius: 20px;
  height: 250px;
}

.box-pc-3:hover {
  background-color: #0043E7; /* Darker orange on hover */
  border-radius: 20px;
  height: 250px;
}

.box-pc-4:hover {
  background-color: #0043E7; /* Darker red on hover */
  border-radius: 20px;
  height: 250px;
}


.box-mobile-1 {
  background-color: #035CEE; /* Purple */
}

.box-mobile-2 {
  background-color: #035CEE; /* Cyan */
}

.box-mobile-3 {
  background-color: #035CEE; /* Yellow */
}

.box-mobile-4 {
  background-color: #035CEE; /* Indigo */
}
/* Styling the button inside the box */
.box-button {
  margin-top: 10px; /* Space between the text and the button */
  padding: 10px 20px; /* Padding around the button text */
  background-color: #FFC247; /* White background */
  color: #333; /* Dark text color */
  border: none;
  border-radius: 30px; /* Rounded corners */
  font-size: 14px; /* Font size */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%; /* Makes button take full width of the box */
}

/* Button hover effect */
/* .box-button:hover {
  background-color: #4CAF50; 
  color: white; 
  transform: scale(1.05); 
} */

/* Mobile button styling */

/* General Button Style for mobile */
@media screen and (max-width: 1024px) {
  .box-button {
    width: auto; /* Auto width for buttons on mobile */
    padding: 8px 16px;
  }
}

/* Mobile-specific styles */
@media screen and (max-width: 1024px) {
  .landing-page {
    background: url('../image_About/homephon.jpg') no-repeat center center/cover;
    height: 110vh;
  }

  /* Hide PC images on mobile */
  .pc-images {
    display: none;
  }

  /* Display mobile images in two columns */
  .mobile-images {
    display: grid; /* Use grid layout for a 2-column design */
    grid-template-columns: repeat(2, 1fr); /* 2 columns, each taking equal space */
    gap: 15px; /* Space between the items */
    width: 80%; /* Set width to 90% of the container */
    margin: 0 auto;
    padding-top: 250px;
  }

  /* Styling for each image box */
  .image-box {
    height: 200px; /* Height adjusts based on content */
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%; /* Each image box takes full width of the grid cell */
    transition: transform 0.3s ease-in-out;
  }

  /* Hover effect for mobile image boxes */
  .image-box:hover {
    transform: translateY(-10px);
  }

  
}
