.dashboard-container {
  padding: 20px;
  position: relative;
  width: 90%;
  margin: 0 auto; /* Center the container horizontally */
}

/* Title styling */
.title {
  margin-bottom: 15px;
  font-size: 5em;     /* Smaller title size */
  color: #035CEE;
  text-align: left;
  margin-left: 30px;
}

/* Box wrapper */
.box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.box-item {
  width: 700px; /* Adjust width for a single row */
  height: 250px;
  border-top: 2px solid black;
  border-right: 2px solid black;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 0; /* Remove bottom margin for a cleaner look */
}

/* Box item hover effect */
.box-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Testimonial text */
.testimonial-text {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.testimonial-text p {
  margin-bottom: 5px;
}

/* Author information styling */
.testimonial-author {
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
  align-items: center;
}

.testimonial-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}

.author-name {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

/* Author title styling */
.author-title {
  font-size: 10px;
  color: #888;
}

/* Toggle Arrow Buttons */
.toggle-buttons {
  position: absolute;
  top: 100px;
  right: 100px;
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Arrow button styling */
.arrow-button {
  font-size: 48px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  color: #333;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Arrow button hover effect */
.arrow-button:hover {
  transform: scale(1.2);
  color: #007BFF;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
.testimonial-text {
  font-size:small;
  margin-bottom: 10px;
}
}

/* For small screens (tablets and below) */
@media (max-width: 768px) {
  .title {
    font-size: 3em;  /* Adjust title size for smaller screens */
    margin-left: 10px; /* Reduce left margin */
  }

  .box-wrapper {
    gap: 15px;  /* Reduce gap between boxes */
  }

  /* Box item adjustments for mobile */
  .box-item {
    width: 100%;  /* Full width on smaller screens */
    height: 250px; /* Auto height */
    max-width: 500px; /* Limit the max width */
  }

  /* Only show one testimonial at a time on small screens */
  .box-wrapper > .box-item:nth-child(2),
  .box-wrapper > .box-item:nth-child(3),
  .box-wrapper > .box-item:nth-child(4) {
    display: none; /* Hide the extra boxes */
  }

  .testimonial-text {
    font-size: 12px;  /* Smaller text for small screens */
  }

  .testimonial-author {
    bottom: 10px; /* Adjust position for smaller screens */
    left: 10px;
  }

  .testimonial-photo {
    width: 40px; /* Smaller photo size */
    height: 40px;
  }

  .arrow-button {
    font-size: 36px; /* Smaller arrows on small screens */
  }
}

/* For extra small screens (mobile phones) */
@media (max-width: 480px) {
  .title {
    font-size: 2.5em;  /* Further reduce title size */
    margin-left: 5px;  /* Reduce left margin */
  }

  .box-wrapper {
    gap: 10px;  /* Even smaller gap */
  }

  /* Box item adjustments for mobile */
  .box-item {
    width: 100%;  /* Full width */
    height: 200px; /* Auto height */
    padding: 10px; /* Reduce padding */
  }

  .testimonial-text {
    font-size: 10px;  /* Even smaller text */
  }

  .testimonial-author {
    bottom: 5px; /* Adjust position for very small screens */
    left: 5px;
  }

  .testimonial-photo {
    width: 30px;  /* Even smaller photo size */
    height: 30px;
  }

  .arrow-button {
    font-size: 28px; /* Smaller arrows on mobile */
  }
}
