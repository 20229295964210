.NavbarOne {
  background-color:white; /* Always dark background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between; /* Space between left and right items */
  align-items: center; /* Center the items vertically */
  padding: 20px 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 1000;
}

.NavbarOne-left {

  display: flex;
  align-items: center; /* Align logo and links vertically */
}

.NavbarOne-logo {
  width: 150px; /* Set the width of the logo */
  transition: all 0.3s ease;
}

.NavbarOne-nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  margin-left: 50px;
  padding: 0;
}

.NavbarOne-nav-links li {
  margin: 0 15px;
}

.NavbarOne-nav-links a {
  color: black; /* Navbar text color */
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.NavbarOne-nav-links a:hover {
  color: #003366; /* Navbar hover color */
}

.NavbarOne-right {
  display: flex;
  align-items: center; /* Ensures that items are vertically centered */
  justify-content: flex-end; /* Aligns items to the right */
}

.NavbarOne-right ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.NavbarOne-right li {
  margin: 10px 15px;
}

.NavbarOne-right a {

  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}




/* Styling for Buy Now Button */
.buy-now-button {
  background-color: #035CEE; /* Yellow background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 30px; /* Rounded corners */
  font-size: 16px;
  text-align: center;
  display: inline-block;
  transition: background-color 0.3s ease;
  border: none; /* Remove any default border */
  text-decoration: none; /* Remove underline */
}

.buy-now-button:hover {
  background-color: #ffcc00; /* Slightly darker yellow on hover */
}

/* Mobile menu styles */
.NavbarOne-nav-links.mobile {
  display: block;
  position: absolute;
  top: 80px;
  right: 0;
  margin-right: 20px;
  background-color: #ffffff; /* Mobile navbar background color */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

.NavbarOne-nav-links.mobile.active {
  display: block;
}

.NavbarOne-dropdown {
  position: relative;
}

.NavbarOne-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff; /* Dropdown background color */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  min-width: 150px;
  padding: 10px 0;
  border-radius: 5px;
}

.NavbarOne-dropdown:hover .NavbarOne-dropdown-menu {
  display: block;
}

.NavbarOne-dropdown-menu a {
  padding: 10px;
  display: block;
  color: #035CEE; /* Dropdown text color */
}

.NavbarOne-dropdown-menu a:hover {
  background-color: #003366; /* Dropdown hover color */
  color: white; /* Dropdown hover text color */
}

.NavbarOne-menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .NavbarOne-menu-icon {
    display: block;
    font-size: 24px;
    color: white;
  }

  .NavbarOne-nav-links {
    display: none;
    width: 50%;
    margin-top: 20px;
  }

  .NavbarOne-dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -173px;
    background-color: #ffffff;
    min-width: 150px;
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .NavbarOne-nav-links.mobile {
    display: block;
  }

  .NavbarOne-nav-links li {
    margin: 10px 0;
    text-align: center;
  }

  .NavbarOne-nav-links a {
    font-size: 18px;
  }
  .NavbarOne-right {
    display: none;
  }
}
/* ContactSales.css */

/* Style for the Contact Sales link */
.contact-sales-link {
  font-size: 16px;
  color: black; /* Blue color for the link */
  text-decoration: none;
  padding: 5px 10px;
  
  display: inline-block;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
/* Mobile menu styles */


/* Ensure Buy Now button has white text color on mobile */
.NavbarOne-nav-links.mobile .buy-now-button {
  color: white; /* Force white text color */
}
