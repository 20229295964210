/* Default styles for larger screens */
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.about-image,
.about-description {
  flex: 1; /* Ensures both columns are equal width */
  padding: 20px;
}

.about-image img {
  width: 100%; /* Make image take up the full width of the container */
  height: auto;
  border-radius: 10px;
}

.about-description h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.about-description p {
  font-size: 1.2em;
  color: #666;
  line-height: 1.6;
}

/* Responsive styles for smaller screens (e.g., tablets and mobile) */
@media (max-width: 768px) {
  .about-container {
    padding: 15px; /* Reduce padding */
  }

  .about-content {
    flex-direction: column; /* Stack image and description vertically */
    justify-content: center; /* Center content vertically */
  }

  .about-image,
  .about-description {
    flex: none; /* Remove equal flex width */
    width: 100%; /* Make each section take up full width */
    padding: 10px; /* Reduce padding */
  }

  .about-description h2 {
    font-size: 1.8em; /* Slightly smaller font size */
  }

  .about-description p {
    font-size: 1.1em; /* Slightly smaller font size */
  }
}

/* Further adjustments for very small screens (e.g., mobile devices) */
@media (max-width: 480px) {
  .about-container {
    padding: 10px; /* Reduce padding even more */
  }

  .about-description h2 {
    font-size: 1.6em; /* Reduce font size further */
  }

  .about-description p {
    font-size: 1em; /* Reduce font size for smaller screens */
  }
}
