.land-about {
  height: 80vh;
  background: url('../finalImage/aboutabout.jpg') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 20px;
}

@media screen and (max-width: 1024px) {
  .land-about {
      background: url('../image_About/Android\ Compact\ -\ 2\ \(2\).jpg') no-repeat center center/cover;
  }
}
