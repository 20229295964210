/* Base styles for larger screens (desktop) */
.partfive-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text inside the container */
  padding: 20px;
  min-height: 60vh; /* Make the container take full height of the screen */
}

.partfive-heading {
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 20px;
}

.partfive-description {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 30px;
  width: 90%;
  margin: 0 auto;
  color: #333;
}

.partfive-boxes {
  display: flex;
  justify-content: center; /* Center boxes horizontally */
  gap: 10px;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.partfive-box {
  width: 190px;
  height: 139px;
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.box-number {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 10px;
}

.box-content {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  text-align: center;
}

/* Responsive styles for tablets and mobile devices */
@media screen and (max-width: 1024px) {
  .partfive-boxes {
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
  }
  
  .partfive-box {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .partfive-heading {
    font-size: 2rem;
  }
  
  .partfive-description {
    font-size: 1rem;
  }

  .partfive-boxes {
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
  }

  .partfive-box {
    width: 95%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .partfive-heading {
    font-size: 1.5rem;
  }

  .partfive-description {
    font-size: 0.9rem;
  }

  .partfive-boxes {
    gap: 10px;
    margin: 0 auto;
  }

  .partfive-box {
    width: 95%;
    height: auto;
  }
}
