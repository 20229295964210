/* General Footer Styling */
.footer {
  background-color: #0A0A23;
  color: #FFFFFF;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-left,
.footer-middle,
.footer-right {
  flex: 1;
  min-width: 250px;
  margin: 20px;
}

/* Footer Left Section */
.footer-logo {
  width: 150px;
  margin-bottom: 10px;
}

.tagline {
  font-size: 1.1rem;
  margin: 10px 0;
}

.footer-description {
  font-size: 0.8rem;
  margin-top: 20px;
  line-height: 1.4;
  text-align: justify;
}

.social-links a {
  font-size: 1.5rem;
  color: #FFFFFF;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #FFC107;
}

/* Legal Links */
.footer-legal-links {
  margin-top: 10px;
}

.footer-legal-links .footer-link {
  color: #FFFFFF;
  margin-right: 15px;
  font-size: 0.9rem;
  text-decoration: none;
}

.footer-legal-links .footer-link:hover {
  text-decoration: underline;
}

/* Middle Section */
.footer-middle {
  text-align: left;
}

.footer-middle .footer-title {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.footer-links-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links-list li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.footer-links-list a {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: lighter;
}

.footer-links-list a:hover {
  text-decoration: underline;
}

.link-icon {
  margin-right: 10px;
  color: #FFFFFF;
}

/* Right Section - Newsletter and Contact Info */
.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.newsletter-section {
  background-color: #FFC107;
  padding: 20px;
  border-radius: 10px;
  color: #0A0A23;
  text-align: left;
  max-width: 350px;
  margin-bottom: 20px; /* Space between newsletter and contact info */
}

.newsletter-title {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.newsletter-text {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.newsletter-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.newsletter-input {
  padding: 10px;
  border: none;
  border-radius: 20px 0 0 20px;
  outline: none;
  font-size: 0.9rem;
  flex: 1;
}

.newsletter-submit {
  background-color: #0A0A23;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.3s;
}

.newsletter-submit:hover {
  background-color: #333;
}

/* Contact Info Section */
.contact-info {
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
  max-width: 350px;
  font-size: 0.9rem;
}

.contact-info p {
  margin: 5px 0;
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  padding-top: 20px;
  font-size: 0.8rem;
  color: #BBBBBB;
  border-top: 1px solid #333;
}

.footer-bottom a {
  color: #FFC107;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

/* Media Queries for Responsiveness */

/* For small screens like mobile phones */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack the footer sections vertically */
    align-items: center; /* Center-align the sections */
  }

  .footer-left, .footer-middle, .footer-right {
    min-width: 100%; /* Allow each section to take full width */
    margin: 10px 0; /* Reduce margin between sections */
  }

  .footer-logo {
    width: 120px; /* Slightly smaller logo */
  }

  .social-links a {
    font-size: 1.2rem; /* Reduce the size of social icons */
    margin-right: 10px;
  }

  .footer-description {
    font-size: 0.9rem; /* Increase font size slightly for readability */
  }

  .newsletter-section {
    max-width: 100%; /* Allow newsletter to take full width */
    margin-bottom: 10px;
  }

  .contact-info {
    max-width: 100%; /* Allow contact info to take full width */
  }

  .footer-bottom {
    font-size: 0.9rem; /* Slightly larger footer text on small screens */
  }
}

/* For very small screens like phones in portrait orientation */
@media (max-width: 480px) {
  .footer {
    padding: 20px 10px; /* Less padding for smaller screens */
  }

  .footer-left, .footer-middle, .footer-right {
    margin: 5px 0; /* Reduce margins further */
  }

  .footer-logo {
    width: 100px; /* Even smaller logo */
  }

  .social-links a {
    font-size: 1.1rem; /* Smaller social icon size */
  }

  .newsletter-title {
    font-size: 1.1rem; /* Slightly smaller title */
  }

  .footer-links-list li {
    font-size: 0.9rem; /* Slightly smaller font for footer links */
  }
}
