/* Base styles for larger screens (desktop) */
.imgtexone_container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* 
    Space between columns */
  }
  
  
  .imgtexone_leftColumn {
    flex: 1;
    max-width: 50%; /* Limit width to 50% */
  }
  
  .imgtexone_rightColumn {
    flex: 1;
    max-width: 40%; /* Limit width to 40% */
    padding: 60px;
  }
  
  .imgtexone_heading {
    font-size: 24px;
    color: #003366;
    margin-bottom: 10px;
  }
  
  .imgtexone_description {
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .imgtexone_imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .imgtexone_mainImage {
    width: 336px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #ddd;
  }
  
  .imgtexone_thumbnailContainer {
    display: flex;
    justify-content: center; /* Center thumbnails horizontally */
    gap: 10px; /* Space between thumbnails */
    margin-top: 10px; /* Add some spacing between the main image and thumbnails */
  }
  
  .imgtexone_thumbnail {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #ddd;
  }
  
  /* Responsive styles for tablets and mobile devices */
  @media screen and (max-width: 1024px) {
    .imgtexone_container {
      flex-direction: column; /* Stack columns vertically on smaller screens */
      gap: 10px; /* Reduce space between stacked columns */
    }
  
    .imgtexone_leftColumn, .imgtexone_rightColumn {
      max-width: 100%; /* Allow columns to take full width */
    }
  
    .imgtexone_rightColumn {
      padding: 10px; /* Reduce padding for smaller screens */
      margin-left: 40px;
    }
  
    .imgtexone_mainImage {
      width: 100%; /* Make the main image responsive */
      height: auto; /* Maintain aspect ratio */
    }
  
    .imgtexone_thumbnailContainer {
      flex-direction: row; /* Align thumbnails horizontally */
      flex-wrap: wrap; /* Allow thumbnails to wrap to the next line if needed */
      gap: 10px; /* Maintain space between thumbnails */
    }
  }
  
  @media screen and (max-width: 768px) {
    .imgtexone_heading {
      font-size: 20px; /* Adjust heading size for smaller screens */
    }
  
    .imgtexone_description {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  
    .imgtexone_mainImage {
      width: 100%; /* Make the main image responsive */
      height: auto; /* Maintain aspect ratio */
    }
  
    .imgtexone_thumbnail {
      width: 60px; /* Adjust thumbnail size for smaller screens */
      height: 60px; /* Adjust thumbnail size for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .imgtexone_heading {
      font-size: 18px; /* Further adjust heading size for very small screens */
    }
  
    .imgtexone_description {
      font-size: 12px; /* Further adjust font size for very small screens */
      margin-right: 29px;
    }
    .imgtexone_mainImage {
      width: 80%; /* Set image width to 80% */
      height: auto; /* Maintain aspect ratio */
      display: block; /* Makes sure margin auto works */
      margin: 0 auto; /* Centers the image horizontally */
    }
    
    .imgtexone_thumbnail {
      width: 50px; /* Further adjust thumbnail size for very small screens */
      height: 50px; /* Further adjust thumbnail size for very small screens */
    }
  }
  