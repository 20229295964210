.mfour-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add space between columns */
  align-items: flex-start;
  padding: 20px;
  width: 80%; /* Adjust width as needed */
  margin: 0 auto; /* This centers the container */
}
.m9-header {
  font-size:2.5rem ;
  font-weight: bold; /* Make the header bold */
  color: #035cee; /* Custom color for the header */
  text-align: left;
}
.mfour-description {
  flex: 1;
  max-width: 50%;
}

.mfour-images {
  flex: 1;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mfour-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Optional: Add some padding and margin for better spacing */
.mfour-container h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.mfour-container p {
  font-size: 16px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .m9-header {
    font-size:20px;
  }
  .mfour-container {
    flex-direction: column;
    align-items: center;
  }
  .mfour-description, .mfour-images {
    max-width: 100%;
  }
}


