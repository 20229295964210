.m5-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Add space between columns */
    align-items: flex-start;
    padding: 20px;
    width: 80%; /* Adjust width as needed */
    margin: 0 auto; /* This centers the container */
  }
  
  .m5-description {
    flex: 1;
    max-width: 50%;
  }
  
  .m5-images {
    flex: 1;
    max-width: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .m5-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Optional: Add some padding and margin for better spacing */
  .m5-container h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .m5-container p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .m5-container {
      flex-direction: column;
      align-items: center;
    }
    .m5-description, .m5-images {
      max-width: 100%;
    }
  }
  